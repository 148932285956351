<template>
    <el-container style="height:100vh;" v-loading="lading"></el-container>
</template>
<script>
export default {
  data () {
    return {
      lading: true
    }
  },
  mounted () {
    const queryNow = this.$route.query // 获取到页面传过来的路径

    // 将要跳转的路径
    const { pathUrl } = this.$route.query

    // 删除
    delete queryNow.pathUrl
    // 替换
    this.$router.replace({
      path: pathUrl, // 地址
      query: queryNow // 参数
    })
  }
}
</script>
